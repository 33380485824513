<template>
  <favorites-component/>
</template>

<script>
  import FavoritesComponent from './components/favorites.component';

  export default {
    name: 'FavoritesContainer',
    components: {
      FavoritesComponent,
    },
    mounted() {
      this.$bus.$emit('updateTitle', 'Wishlist');
    },
  }
</script>
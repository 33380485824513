<template>
  <div class="favorites">
    <account-page-title title="Wishlist" class="favorites__title" />

    <div class="favorites__list">
      <div
        v-for="(product, index) in products"
        :key="index"
        class="favorites__item"
      >
        <product-item-component v-if="product" :product="product" />
      </div>
    </div>

    <error-message-component
      v-if="!isHaveProducts && !loading"
      title="Nothing to see here yet"
      subtitle="Browse our products and save your favorite items"
      class="mx-auto"
    />

    <loader v-if="loading" color="#343434" position="relative" class="mt-5" />
  </div>
</template>

<script>
  import AccountPageTitle from '../../../components/account-page-title.component';
  import ProductItemComponent from '@/shared/components/product-item.component';
  import ErrorMessageComponent from '@/shared/components/error-message.component';
  import Loader from '@/components/loader/loader';

  import { mapGetters } from 'vuex';

  export default {
    name: 'FavoritesComponent',
    components: {
      AccountPageTitle,
      ProductItemComponent,
      Loader,
      ErrorMessageComponent,
    },
    computed: {
      ...mapGetters({
        products: 'wishlist/getList',
        loading: 'wishlist/getLoading',
      }),
      isHaveProducts() {
        return Object.keys(this.products).length;
      },
    },
  };
</script>

<style scoped lang="scss">
  .favorites {
    margin-bottom: 60px;
    color: #343434;

    &__title {
      margin-bottom: 20px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    &__item {
      width: 220px;
    }

    @media (max-width: 767px) {
      &__item {
        width: calc(50% - 10px);
      }
    }
  }
</style>

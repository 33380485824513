<template>
  <div class="sp-error-message d-flex" :style="{ maxWidth: width }">
    <div class="sp-error-message__icon">
      <svg
        width="133"
        height="134"
        viewBox="0 0 133 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.7147 84.4289L39.127 85.8407L58.8456 94.0462L59.4305 92.6374C62.3923 85.5151 60.3813 77.8801 54.9503 75.6184C52.2305 74.489 49.1275 74.8376 46.1973 76.6037C43.4199 78.2806 41.1178 81.0609 39.7147 84.4289ZM53.7738 78.4419C54.448 78.7185 55.0415 79.1277 55.5515 79.6376C57.7325 81.8187 58.3923 85.8955 57.1246 90.0271L43.1856 84.2244C44.338 82.0808 45.9313 80.332 47.7724 79.2227C49.8468 77.9723 51.9788 77.6957 53.7738 78.4419Z"
          fill="#E3E5FC"
        />
        <path
          d="M41.8551 82.4206C38.6253 81.0751 35.1189 82.0403 34.0702 84.5642L15.1323 114.543C14.0836 117.067 15.867 120.233 19.0968 121.578L43.0191 131.539L53.858 87.4166L41.8551 82.4206Z"
          fill="#31B77F"
        />
        <path
          d="M53.6667 130.588L62.3908 96.3539C63.4396 93.83 61.6561 90.6636 58.4263 89.3181L53.8539 87.4136L43.015 131.536L45.8789 132.728C49.1116 134.077 52.6179 133.112 53.6667 130.588Z"
          fill="#21A66E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.9348 52.3756C34.2204 52.8571 35.4912 53.0954 36.7074 53.0954C38.3753 53.0954 39.9489 52.6437 41.3091 51.7502C45.9901 48.6973 46.5808 41.2761 42.6196 35.2101L41.6019 33.6514L24.625 44.7459L25.6426 46.3046C27.5041 49.154 30.0953 51.3084 32.9348 52.3756ZM34.2384 48.8904C32.64 48.2898 31.1359 47.2027 29.875 45.7532L40.4136 38.873C42.2255 42.7648 41.8284 46.9694 39.2768 48.6373C37.9316 49.5159 36.1396 49.6052 34.2384 48.8904Z"
          fill="#E3E5FC"
        />
        <path
          d="M42.6302 38.0998C45.281 36.3674 46.3433 33.255 44.993 31.185L32.2157 1.75332C30.8655 -0.316672 27.5892 -0.594657 24.9335 1.13778L5.30078 13.9598L32.7816 44.5332L42.6302 38.0998Z"
          fill="#A7ADFF"
        />
        <path
          d="M0.58612 22.4037L21.7477 46.3601C23.0979 48.4301 26.3742 48.7081 29.0299 46.9756L32.7827 44.5284L5.30193 13.955L2.95396 15.4889C0.298207 17.2213 -0.764092 20.3337 0.58612 22.4037Z"
          fill="#978CFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M83.8424 37.4183L84.3835 39.1954L109.268 31.6104L108.727 29.8332C107.431 25.5791 104.974 21.9305 101.812 19.5677C98.4713 17.0757 94.7434 16.2815 91.3182 17.3239C87.893 18.3713 85.2422 21.1115 83.8622 25.038C82.5517 28.766 82.5468 33.1641 83.8424 37.4183ZM92.405 20.8826C93.0205 20.6939 93.6609 20.5996 94.3112 20.5996C96.0436 20.5996 97.8654 21.2648 99.583 22.5455C101.683 24.1141 103.405 26.4224 104.542 29.1625L86.9446 34.5237C86.3638 31.6197 86.5028 28.7406 87.3715 26.2685C88.3494 23.4887 90.1364 21.5726 92.405 20.8826Z"
          fill="#E3E5FC"
        />
        <path
          d="M84.2933 33.8643C80.2129 35.1053 77.6713 38.729 78.6393 41.9159L84.1047 84.7703C85.0777 87.9572 89.2077 89.5456 93.2881 88.3046L123.489 79.1063L99.4435 29.2527L84.2933 33.8643Z"
          fill="#FAE026"
        />
        <path
          d="M132.756 69.9528L114.394 31.025C113.421 27.8381 109.291 26.2496 105.211 27.4906L99.4375 29.2479L123.483 79.1015L127.097 77.9995C131.182 76.7584 133.724 73.1347 132.756 69.9528Z"
          fill="#FDC510"
        />
      </svg>
    </div>

    <slot name="prepend" />

    <h3 v-if="title" class="sp-error-message__title">
      {{ title }}
    </h3>

    <p v-if="subtitle" class="sp-error-message__subtitle">
      {{ subtitle }}
    </p>

    <slot name="append" />
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      subtitle: {
        type: String,
        default: '',
      },

      width: {
        type: String,
        default: '360px',
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-error-message {
    padding: 100px 0;
    max-width: 360px;
    width: 100%;
    text-align: center;
    flex-direction: column;

    &__icon {
      width: 133px;
      margin: 0 auto 44px;
    }

    &__title {
      color: #343434;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }

    &__subtitle {
      font-weight: 400;
      font-size: 16px;
      color: #343434;
      line-height: 24px;
    }
  }
</style>

<template>
  <favorites-container/>
</template>

<script>
  import FavoritesContainer from './favorites.container';

  export default {
    name: 'FavoritesView',
    components: {
      FavoritesContainer,
    },
  }
</script>